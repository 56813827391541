@media screen and (max-width: 1400px) {
    
}
@media screen and (max-width: 1280px) {
    .topic{
        font-size: 40px !important;
      }
      .topic-small{
        font-size: 30px !important;
      }
      .sub-topic{
        font-size: 22px !important;
      }
      .sub-topic-small{
        font-size: 18px !important;
      }
}
@media screen and (max-width: 768px) {

}
@media screen and (max-width: 490px) {
  .modal.show .modal-dialog {

    margin: 0 20px;
}
.box-thankyou{
  width: 80%;
}
}