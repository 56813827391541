$text-white:#fff;
$text-black:#000;
$text-gray:#C4C4C4;
$bg-main: linear-gradient(117deg, #EE2A7B 0%, #FDB913 100%);
$bg-black:#000;
$bg-gray-b:#333132;
$bg-pink:#EE2A7B;
$bg-red:#E5252A;
$bg-gray: #F5F5F5;
$bg-gray-c: #C8C8C8;
$green: #597E52;
$orange: #FF8A35;
$blue: #2A68C5;
$size10:10px;
$size12:12px;
$size14:14px;
$size16:16px;
$size18:18px;
$size20:20px;
$size22:22px;
$size24:24px;
$size28:28px;