@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;700&display=swap');

@import '../scss/sizeColor';

body{
    font-family: 'Heebo', sans-serif !important;
}

.bg-main{
    // background: $bg-main;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    overflow: hidden;
}
.box-in{
    border-radius: $size22;
}

.box-in-res{
    border-radius: 50px 50px 0px 0px;
    padding-bottom: 215px;
}
.box-footer{
    bottom: $size10;
    left: 50%;
    transform: translate(-50%, 0);
}

#select-start,#select-end{
    background-color: unset !important;
    border-top: unset !important;
    border-left: unset !important;
    border-right: unset !important;
    border-radius: 0 !important;
    color: $text-white;
    padding:.375rem .375rem !important;
    font-weight: 300 !important;
}
select#select-start option,select#select-end option {
    margin: 40px;
    background: $bg-gray-b;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
   max-height: 50px !important;
  }
#select-start:focus-visible,#select-end:focus-visible{
    background-color: unset !important;
    box-shadow: unset !important;
    border-color: #D9D9D9 !important;
    color: $text-white !important;
}

.input-custom:focus{
    box-shadow: unset;
    border-color: #D9D9D9;
}
.input-login{
    padding: 12px 15px;
    border-radius: 10px;
    border: 0px solid #fff;
    background: var(--EAEA-White-100, $text-white);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.input-login:focus{
    // border: 1px solid $bg-pink;
    background-color: $text-white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
input::placeholder{ 
    color: #000 !important;
  }

.select-login{
    padding: 12px 15px;
    border-radius: 10px;
    border: 0px solid #fff;
    // background: var(--EAEA-White-100, $text-white);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.select-login:focus{
    // border: 1px solid $bg-pink;
    background-color: $text-white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.hr-custom{
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom:1px solid #D9D9D9;
}
.hr-custom:last-child{
    border-bottom:unset
}
.bg-gray{
    background-color: $bg-gray;
}
.text-gray{
    color:$text-gray
}
.absolute-center{
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.absolute-center-bottom{
    bottom: 15px;
    left: 50%;
    transform: translate(-50%,0%);
}
.absolute-right-bottom{
    bottom: 0px;
    right: 0px;
    
}
  .absolute-top-start{
    top: 10px;
    left: 10px;
  }
  .absolute-top-end{
    top: 10px;
    right: 10px;
  }
  .absolute-center-right{
    top: 50%;
    right: 0;
    transform: translate(0,-50%);
  }

  .text-green{
    color: $green;
  }
  .box-payment{
    border-radius: 15px;
    border: 2px solid var(--EAEA-3EAEA-100, #C6A969);
    background: var(--EAEA-White-100, #FFF);
    transition: 0.5s;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;
  }
  .box-payment:hover,.box-payment.active{
    background: #F1E4C3;
    transition: 0.5s;
  }
  .bb-mark{
    position: absolute;
    right: -15px;
    top: -15px;
    opacity: 0;
  }
  .box-payment.active .bb-mark{
    opacity: 1;
  }
  .box-thankyou{
    background: $green;
    border-radius: $size12;
    padding: 45px 45px;
    transition: 0.5s;
    border-radius: 10px;
    color: $text-white;
    background: var(--EAEA-Gree-G, linear-gradient(180deg, #81B678 0%, #597E52 100%));
    text-decoration: none;
    border: unset;
    // display: inline-block;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%,-50%);
  }
  .box-green{
    background: $green;   
    transition: 0.5s;
    color: $text-white;
    background: var(--EAEA-Gree-G, linear-gradient(180deg, #81B678 0%, #597E52 100%));
    text-decoration: none;
    border: unset;   
  }
  table.table-custom {
    border:#D9D9D9;
    border-radius: 8px;
    overflow: hidden;
  }
  table.table-custom thead tr th{
    background-color: #E2D3AA;
  }
  .status-waiting{
    background-color: #C6A969;
    color: $text-white;
    padding: 3px 30px;
    border-radius: 30px;
    display: block;
    text-align: center;
    line-height: 1.1;
  }
  .status-success{
    background-color: #597E52;
    color: $text-white;
    padding: 3px 30px;
    border-radius: 30px;
    display: block;
    text-align: center;
  }
  .status-cancel{
    background-color: #A82424;
    color: $text-white;
    padding: 3px 30px;
    border-radius: 30px;
    display: block;
    text-align: center;
  }
  .box-registrat{
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    fill: var(--EAEA-White-100, #FFF);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-top-left-radius: 7px;
    overflow: hidden;
    border-top-right-radius: 7px;
  }
  .box-menu-res{
    width: 0px;
    background: #fff;
    right: 0;
    top: 45px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: 0.3s;
    border-radius: 7px;
    opacity: 0;
    z-index: -90;
  }
  .active .box-menu-res{
    width: 150px;
    opacity: 1;
    transition: 0.5s;
    z-index: 99;
    visibility: visible;
  }
  i {
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left:-15px;
  }
  .card {
    background: white;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #C8D0D8;
    display: inline-block;
    margin: 0 auto;
  }
  ._failed{ border-bottom: solid 4px red !important; }
._failed i{  color:red !important;  }

._success {
    box-shadow: 0 15px 25px #00000019;
    padding: 45px;
    width: 100%;
    text-align: center;
    margin: 40px auto;
    border-bottom: solid 4px #28a745;
    background: #fff;
}

._success i {
    font-size: 55px;
    color: #28a745;
}

._success h2 {
    margin-bottom: 12px;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 10px;
}

._success p {
    margin-bottom: 0px;
    font-size: 18px;
    color: #495057;
    font-weight: 500;
}