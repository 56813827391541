@import '../scss/sizeColor';

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background:  $bg-gray-c;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background:  $bg-gray-c;
}

::-webkit-scrollbar-thumb:active {
  background:  $bg-gray-c;
}

::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 50px;
}
//btn button
::placeholder {
    color: #D9D9D9 !important;
    opacity: 1; /* Firefox */
  }
  
  ::-ms-input-placeholder { /* Edge 12-18 */
    color:#D9D9D9 !important;
  }
  .text-red{
    color: $bg-red;
  }
  .text-pink{
    color: $bg-pink;
  }
  .cursor-pointer{
    cursor: pointer;
  }
 
.a-default,.btn-default:hover{
  // color: $bg-pink;
  transition: 0.5s;
}
.btn-default{
  color: $text-black;
  text-decoration: none;
  transition: 0.5s;
}
.btn-cancle{
  background-color: unset;
}
.btn-system,.btn-menu{
    background-color: $bg-gray;
    border-radius: $size12;
    transition: 0.5s;
}
.btn-menu:hover{
    background-color: $bg-gray;
    transition: 0.5s;
}

.btn-submit{
    background: $green;
    border-radius: $size12;
    padding: 14px 30px;
    transition: 0.5s;
    border-radius: 100px;
    color: $text-white;
    background: var(--EAEA-Gree-G, linear-gradient(180deg, #81B678 0%, #597E52 100%));
    text-decoration: none;
    border: unset;
/* SD */

}
.btn-submit:hover{
    transition: 0.5s;
    // background: $green; 
    color: $text-white;   
    box-shadow: 0px 7px 18.8px 0px #375032;
    // transform: translateY(-4%);

}
.btn-del{
    background: $bg-red;
    border-radius: $size12;
    padding: 11px 30px;
    transition: 0.5s;
}
.btn-del:hover{
    transition: 0.5s;
    background: $bg-red;
    color: $text-white;
    box-shadow: 0px 5px 15px 0px rgba(229, 37, 42, 0.30);
    transform: translateY(-4%);
}
.btn-del-icon:hover{
  background-color: $text-white;
  transition: 0.5s;
  border-radius: 3px;
}

  .ex-checkbox4,
  .ex-radio4 {
    position: relative;
    margin: 0;
    font-size: 16px;
  }
  .ex-checkbox4 label{
    // padding-left: 0px;
    // font-weight: 400;
    // display: inline-block;
    // position: relative;
    // margin: 0;
    // width: 100%;
    padding-left: 23px;
    // border-radius: 6px;
  }
  .ex-radio4 label {
    padding-left: 23px;
    // line-height: 140%;
    // font-weight: 500;
    // display: inline-block;
    // position: relative;
    // margin: 0;
    // width: 100%;
    // border-radius: 6px;
  }
  
  .ex-checkbox4 input[type="checkbox"]{
    margin: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 10;
    left: 0;
    position: absolute;
  }
  .ex-radio4 input[type="radio"] {
    margin: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 24px;
    z-index: 10;
    left: 0;
    position: absolute;
  }
  .ex-checkbox4 input[type="checkbox"] + label::before{
    width: 23px;
    height: 23px;
    content: "";
    position: absolute;
    left: 0px;
    background-color: #BFC0BC;
    border-radius: 6px;
    // border: 2px solid $bg-pink;
  }
  .ex-radio4 input[type="radio"] + label::before {
    /* border: 1px solid #abe6cd; */
    width: 23px;
    height: 23px;
    content: "";
    position: absolute;
    left: 0px;
    background-color: #BFC0BC;
    border-radius: 6px;
    // border: 2px solid $bg-pink;
  }

  .ex-checkbox4 input[type="checkbox"]:focus + label::before,.ex-radio4 input[type="radio"]:focus + label::before {
    /* box-shadow: 0 0 1px #abe6cd; */
    outline: none;
  }
 
  .ex-checkbox4 input[type="checkbox"]:checked + label::before,.ex-radio4 input[type="radio"]:checked + label::before {
    /* border: 1px solid #abe6cd; */
    background: url("../img/icon/Check.svg") no-repeat center center;
    background-size: 12px 12px;
    background-color: #BFC0BC;
  }

  .ex-checkbox4 input[type="checkbox"]:disabled,.ex-radio4 input[type="radio"]:disabled {
    cursor: not-allowed;
  }
  
  .ex-checkbox4 input[type="checkbox"]:disabled + label,.ex-radio4 input[type="radio"]:disabled + label {
    color: #bcbcbc;
    cursor: not-allowed;
  }
  
  .ex-checkbox4 input[type="checkbox"]:disabled:checked + label::before,.ex-radio4 input[type="radio"]:disabled:checked + label::before {
    border: 1px solid transparent;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBmaWxsPSIjYmNiY2JjIiBkPSJNMTguOSA4LjhsLTguNyA4LjdjLS4xLjEtLjIuMS0uMyAwbC00LjktNC45Yy0uMS0uMS0uMS0uMiAwLS4zbDEuMi0xLjJjLjEtLjEuMi0uMS4zIDBsMy42IDMuNiA3LjMtNy4zYy4xLS4xLjItLjEuMyAwbDEuMiAxLjJjLjEgMCAuMS4xIDAgLjJ6IiAvPjwvc3ZnPg==")
      no-repeat center center;
  }
  
  .ex-checkbox4 input[type="checkbox"]:disabled + label::before,.ex-radio4 input[type="radio"]:disabled + label::before {
    border: 1px solid #bcbcbc;
  }
  
  .box-menu{
    width: 0;
    height: 0;
    opacity: 0;
    transition: 0.3s;
    top: 32px;
    border-radius: 12px;
  }
#menu-active .box-menu{
    background-color: $bg-gray;
    top: 32px;
    width: 300px;
    height: 88%;
    opacity: 1;
    z-index: 9;
    transition: 0.5s;
    visibility: visible;
}
ul.menu-m {
  padding: 20px 15px 20px 15px;
  margin: 0;
}

ul.menu-m li {
  list-style: none;
  text-align: left;
  padding: 8px 0px 8px 0px;
  border-bottom: 1px solid #e4e2dd;
}

ul.menu-m li a {
  text-decoration: none;
  font-size: 26px;
  font-weight: 500;
  padding: 5px 0;
  color: #000;
  transition: 0.5s;
}

ul.menu-m li a:hover,ul.menu-m li a.active {
  color: $bg-pink;
  transition: 0.5s;
}

ul.menu-m li:last-child {
  border-bottom: unset;
}

.bg-menu-m {
  /* background-image: url('../img/bg-menu.jpg'); */
  background-color: #fff;
  top: 0;
}

.menu-open .submenu {
  display: block;
  transition: 0.5s;
}
ul.menu-m li.menu-open a{
  color: $bg-pink;
  transition: 0.5s;
}

.menu-openB .submenuB {
  display: block;
}

.menu-open ul.submenu li {
  border-bottom: unset;
}
.menu-open ul.submenu li a{
 font-size: 18px;
 color: #000;
 transition: 0.5s;
}
.menu-open ul.submenu li a:hover,.menu-open ul.submenu li a.active{
  color: $bg-pink;
  transition: 0.5s;
}
.nav-link:focus-visible{
  box-shadow: unset;
}
.box-lob{
  background: rgb(217, 217, 217);
  font-size: 52px;
}

.box-loading{
  width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.4);
    z-index: 99;
}
.lds-facebook {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);  
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
.form-control.is-invalid, .was-validated .form-control:invalid,.form-control.is-valid, .was-validated .form-control:valid{
  // background-image: unset;
}
.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus,.form-control.is-valid:focus, .was-validated .form-control:valid:focus,.form-select.is-invalid:focus, .was-validated .form-select:invalid:focus,.form-select.is-valid:focus, .was-validated .form-select:valid:focus{
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-color: unset;
}

.sh-pass{
  position:absolute;
  right:11px;
  bottom: 11px;
}
